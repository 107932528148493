import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import WhiteDownloadButton from '../../assets/images/download-white-button.png';
import BlackDownloadButton from '../../assets/images/download-black-button.png';
import Image from '../Image';

const DownloadButton = ({ resource, white = false }) => (
  <div className="resource-download-button">
    <Image
      height="35px"
      width="35px"
      alt={resource.title}
      src={white ? WhiteDownloadButton : BlackDownloadButton}
    />
  </div>
);
const DateOnCard = ({ day, month }) => (
  <div className="my-5 mr-5 resource-card-date-container">
    <div className="resource-card-day">{day}</div>
    <div className="d-flex justify-content-end mb-2 mr-4 w-100">
      <div className="resource-card-date-separator" />
    </div>
    <div className="resource-card-month text-right">{month}</div>
  </div>
);
const ResourceCard = ({ resource, hideDate = false }) => {
  const intl = useIntl();

  return (
    <a
      href={
        resource.postFields.file.localFile?.publicURL ||
        resource.postFields.file.sourceUrl
      }
      target="__blank"
      className="resource-card-link"
    >
      <div className="resource-card my-5">
        {resource.postFields.file.mimeType.includes('image') ? (
          <>
            <Image
              src={
                resource.postFields.file.localFile?.publicURL ||
                resource.postFields.file.sourceUrl
              }
              alt={resource.title}
              height={418}
              width="100%"
              borderRadius={5}
            />
            <div className="resource-card-title-w-image p-4">
              {resource.title}
            </div>
            <div className="resource-card-date-hover pl-4 pt-4 mt-3 mb-4">
              {intl.locale === 'en' ? resource.dateEN : resource.dateFR}
            </div>
            <DownloadButton resource={resource} white />
          </>
        ) : (
          <div className="p-4">
            <div className="resource-card-date mt-3 mb-4">
              {intl.locale === 'en' ? resource.dateEN : resource.dateFR}
            </div>
            <div className="resource-card-title mb-3">{resource.title}</div>
            <div
              className="resource-card-body"
              dangerouslySetInnerHTML={{
                __html: resource.postFields.resourceDescription
              }}
            />
            <DownloadButton resource={resource} />
          </div>
        )}
      </div>
      {!hideDate && <DateOnCard month={resource.month} day={resource.day} />}
    </a>
  );
};

export default ResourceCard;

ResourceCard.propTyes = {
  resource: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    postFields: PropTypes.shape({
      resourceType: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      }),
      file: PropTypes.shape({
        sourceUrl: PropTypes.string,
        mimeType: PropTypes.string
      }),
      resourceDescription: PropTypes.string
    }),

    title: PropTypes.string,
    date: PropTypes.string,
    dateAgoFr: PropTypes.string,
    dateAgoEn: PropTypes.string
  }).isRequired
};
