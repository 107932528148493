import React from 'react';
import PropTypes from 'prop-types';

import MemberCard from './MemberCard';

const MembersList = ({ members }) => {
  if (members.length < 1) {
    return null;
  }
  return (
    <>
      {members.map(({ node: member }) => (
        <MemberCard member={member} key={member.id} />
      ))}
    </>
  );
};
export default MembersList;
MembersList.propTypes = {
  members: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string,
        description: PropTypes.string,
        memberFields: PropTypes.shape({
          country: PropTypes.string,
          memberSince: PropTypes.string
        }),
        avatar: PropTypes.shape({
          url: PropTypes.string
        })
      })
    })
  ).isRequired
};
