/**
 * @params array - graphql Array containing both languages
 * @param currentLang - current locale
 * @returns Array - Array containing items in the desired lang
 */
export default (array = [], currentLang = 'en') => {
  let result = [];
  if (array.length > 1) {
    result = currentLang === 'en' ? array[0].edges : array[1].edges;
  } else {
    result = array[0].edges;
  }
  return result;
};
