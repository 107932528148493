import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Sugar } from 'react-preloaders';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import useWindowDimensions from '../utils/useWindowDimensions';
import bgImg from '../assets/images/general-bg.png';

import Header from './Header';
import Footer from './Footer';
import '../assets/styles/index.css';

const { GATSBY_RECAPTCHA_KEY } = process.env;

const Layout = ({ children, fluid, background = true, pure = false }) => {
  const { width } = useWindowDimensions();
  const smallLimit = 1200;
  const [isSmallScreen, setIsSmallScreen] = useState(width < smallLimit);
  const [didMount, setDidMount] = useState(false);

  useEffect(() => {
    setIsSmallScreen(width < smallLimit);
  }, [width]);

  useEffect(() => {
    if (!didMount) {
      setDidMount(true);
    }
  }, []);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={GATSBY_RECAPTCHA_KEY}
      language="en"
      useRecaptchaNet
      useEnterprise={false}
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body'
      }}
    >
      <Header isSmallScreen={isSmallScreen} />
      <div
        style={{ backgroundImage: `url(${bgImg})` }}
        className={`container${fluid ? '-fluid' : ''} main-layout with-${
          background ? '' : 'no-'
        }background ${pure ? 'px-0' : 'px-5'}`}
      >
        {children}
      </div>
      <Footer siteTitle="African Crossroads" isSmallScreen={isSmallScreen} />
      {didMount && (
        <Sugar
          animation="fade"
          time={1000}
          customLoading={!didMount}
          color="#dd0000"
          background="#ffffff"
        />
      )}
    </GoogleReCaptchaProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
