import React from 'react';
import ReactTooltip from 'react-tooltip';

import MapChart from './MapChart';

function App({ mapTooltip, setMapTooltip, setCountryCode, countryCode }) {
  return (
    <>
      <MapChart
        setTooltipContent={setMapTooltip}
        setCountryCode={setCountryCode}
        countryCode={countryCode}
      />
      <ReactTooltip>{mapTooltip}</ReactTooltip>
    </>
  );
}

export default App;
