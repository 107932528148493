import React from 'react';
import PropTypes from 'prop-types';
import { Col, Button } from 'reactstrap';
import { Link, IntlContextConsumer, useIntl } from 'gatsby-plugin-intl';

import Image from '../Image';
import messages from '../../messages/activities';

const EventCard = ({ event, size }) => {
  const intl = useIntl();
  return (
    <Col xs="12" md={size} className="mb-4 event-card">
      <Link to={`/event/${event.slug}`}>
        <Image
          src={
            event.featuredImage?.node?.localFile?.publicURL ||
            event.featuredImage?.node?.sourceUrl
          }
          alt={event.title}
          height={350}
          width="100%"
          borderRadius={5}
        />
        <div className="event-card-content py-3 px-4">
          {(size === '8' && (
            <div className="d-flex justify-content-between align-items-center h-100">
              <div className="d-flex flex-column justify-content-between h-100 event-card-left-side">
                <div className="d-flex">
                  {event.categories.nodes && event.categories.nodes.length > 0 && (
                    <>
                      <div className="">{event.categories.nodes[0].name}</div>
                      <div className="px-2">-</div>
                    </>
                  )}
                  <IntlContextConsumer>
                    {({ language: currentLocale }) => (
                      <div className="text-capitalize">
                        {currentLocale === 'fr' ? event.dateFr : event.dateEn}
                      </div>
                    )}
                  </IntlContextConsumer>
                </div>
                <div>
                  <div
                    className="event-card-title mb-3"
                    dangerouslySetInnerHTML={{ __html: event.title }}
                  />
                  <div
                    dangerouslySetInnerHTML={{ __html: event.excerpt }}
                    className="event-card-excerpt"
                  />
                </div>
                <div>
                  <Button className="event-card-view-button px-4">
                    {intl.formatMessage(messages.view)}
                  </Button>
                </div>
              </div>
              <div className="event-card-right-side w-100">
                <div className="event-card-day text-left w-100">
                  {event.day}
                </div>
                <div className="d-flex justify-content-end mb-2 mr-4">
                  <div className="event-card-date-separator" />
                </div>
                <div className="event-card-month text-right w-100">
                  {event.month}
                </div>
              </div>
            </div>
          )) || (
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex">
                {event.categories && event.categories.length > 0 && (
                  <>
                    <div className="">{event.categories[0].name}</div>
                    <div className="px-2">-</div>
                  </>
                )}
                <IntlContextConsumer>
                  {({ language: currentLocale }) => (
                    <div className="text-capitalize">
                      {currentLocale === 'fr' ? event.dateFr : event.dateEn}
                    </div>
                  )}
                </IntlContextConsumer>
              </div>
              <div>
                <div
                  className="event-card-title mb-3"
                  dangerouslySetInnerHTML={{ __html: event.title }}
                />
                <div
                  dangerouslySetInnerHTML={{ __html: event.excerpt }}
                  className="event-card-excerpt"
                />
              </div>
              <div>
                <Button className="event-card-view-button px-4">
                  {intl.formatMessage(messages.view)}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Link>
    </Col>
  );
};

export default EventCard;

EventCard.propTyes = {
  event: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    dateAgoFr: PropTypes.string,
    dateAgoEn: PropTypes.string,
    date: PropTypes.string,
    excerpt: PropTypes.string,
    type: PropTypes.string,
    tags: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          name: PropTypes.string
        })
      )
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          name: PropTypes.string
        })
      )
    }),
    author: PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string
      })
    }),
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        publicURL: PropTypes.string,
        uri: PropTypes.string
      })
    })
  }).isRequired
};
