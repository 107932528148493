import { defineMessages } from 'react-intl';

export const scope = 'header';

export default defineMessages({
  about: {
    id: `${scope}.about`,
    defaultMessage: 'About'
  },
  activities: {
    id: `${scope}.activities`,
    defaultMessage: 'Activities'
  },
  community: {
    id: `${scope}.community`,
    defaultMessage: 'Community'
  },
  resources: {
    id: `${scope}.resources`,
    defaultMessage: 'Resources'
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact'
  }
});
