import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';

import ResourceCard from './ResourceCard';

const ResourcesList = ({ resources }) => {
  if (resources.length < 1) {
    return <></>;
  }

  const firstRes = resources[0].node;

  const otherRes =
    resources.length > 1 ? resources.slice(1, resources.length) : [];

  return (
    <>
      <Row>
        <Col>
          <div className="d-flex flex-wrap">
            <div className="my-5 mr-5 first-res-date-container">
              <div className="first-res-day">{firstRes.day}</div>
              <div className="d-flex justify-content-end mb-2 mr-4">
                <div className="first-res-date-separator" />
              </div>
              <div className="first-res-month text-right">{firstRes.month}</div>
            </div>
            <div className="mr-5 highlighted-res-card-container w-100">
              <ResourceCard resource={firstRes} hideDate />
            </div>
            <div className="d-flex align-items-center justify-content-center my-5">
              <div>
                <div className="first-res-title mb-4">{firstRes.title}</div>
                <div
                  className="first-res-desc"
                  dangerouslySetInnerHTML={{
                    __html: firstRes.postFields.resourceDescription
                  }}
                />
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row lg="3" md="2" xs="1">
        {otherRes.map(({ node: resource }) => (
          <Col key={resource.id}>
            <ResourceCard resource={resource} />
          </Col>
        ))}
      </Row>
    </>
  );
};
export default ResourcesList;
ResourcesList.propTypes = {
  resources: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        postFields: PropTypes.shape({
          resourceType: PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.string
          }),
          file: PropTypes.shape({
            sourceUrl: PropTypes.string,
            mimeType: PropTypes.string
          }),
          resourceDescription: PropTypes.string
        }),

        title: PropTypes.string,
        date: PropTypes.string,
        dateAgoFr: PropTypes.string,
        dateAgoEn: PropTypes.string
      })
    })
  ).isRequired
};
