import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby-plugin-intl';

import Image from '../Image';

const MemberCard = ({ member }) => {
  return (
    <Link to={`/member/${member.slug}`} className="community-member-link">
      <div className="d-flex mb-4">
        <Image
          src={member.avatar?.url}
          width="126px"
          height="126px"
          alt={member.name}
          borderRadius={63}
        />
        <div className="m-4 ml-5">
          <div className="community-member-name mb-3">{member.name}</div>
          <div className="community-member-active-since mb-3">
            {member.memberFields?.memberSince}
          </div>
          <div
            className="community-member-desc"
            dangerouslySetInnerHTML={{ __html: member.description }}
          />
        </div>
      </div>
    </Link>
  );
};
MemberCard.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    memberFields: PropTypes.shape({
      country: PropTypes.string,
      memberSince: PropTypes.string
    }),
    avatar: PropTypes.shape({
      url: PropTypes.string
    })
  }).isRequired
};
export default MemberCard;
