import React, { memo } from 'react';
import { ComposableMap, Geographies, Geography } from 'react-simple-maps';
import randomcolor from 'randomcolor';

import AfricaTopojson from '../../constants/africa.json';

const MapChart = ({ setTooltipContent, setCountryCode, countryCode }) => {
  return (
    <>
      <ComposableMap
        width={300}
        height={400}
        projectionConfig={{ center: [25, 0], scale: 250 }}
        data-tip=""
      >
        <Geographies geography={AfricaTopojson} className="community-map">
          {({ geographies }) =>
            geographies.map(geo => (
              <Geography
                key={geo.rsmKey}
                geography={geo}
                onMouseEnter={() => {
                  const { country } = geo.properties;
                  setTooltipContent(country);
                }}
                onMouseLeave={() => {
                  setTooltipContent('');
                }}
                onClick={() => {
                  const { ISO3 } = geo.properties;
                  setCountryCode(ISO3);
                }}
                style={{
                  default: {
                    fill:
                      countryCode === geo.properties.ISO3
                        ? randomcolor({
                            hue: '#dd0000',
                            luminosity: 'bright',
                            seed: geo.properties.ISO3
                          })
                        : randomcolor({
                            hue: 'monochrome',
                            luminosity: 'bright',
                            seed: geo.properties.ISO3
                          }),
                    stroke: '#ffffff',
                    outline: 'none'
                  },
                  hover: {
                    fill: randomcolor({
                      hue: '#dd0000',
                      luminosity: 'bright',
                      seed: geo.properties.ISO3
                    }),
                    outline: 'none'
                  },
                  pressed: {
                    fill: randomcolor({
                      hue: '#dd0000',
                      luminosity: 'bright',
                      seed: geo.properties.ISO3
                    }),
                    outline: 'none'
                  }
                }}
              />
            ))
          }
        </Geographies>
      </ComposableMap>
    </>
  );
};

export default memo(MapChart);
