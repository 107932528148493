import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Button } from 'reactstrap';
import { useIntl, IntlContextConsumer, Link } from 'gatsby-plugin-intl';

import messages from '../messages/activities';
import useWindowDimensions from '../utils/useWindowDimensions';

import UpdateCard from './PostCard';
import Image from './Image';

const UpdatesList = ({ updates }) => {
  const { width } = useWindowDimensions();
  const smallLimit = 1200;
  const extraSmallLimit = 992;
  const [isSmallScreen, setIsSmallScreen] = useState(width < smallLimit);
  const [isExtraSmallScreen, setIsExtraSmallScreen] = useState(
    width < extraSmallLimit
  );

  useEffect(() => {
    setIsSmallScreen(width < smallLimit);
    setIsExtraSmallScreen(width < extraSmallLimit);
  }, [width]);

  const [height, setHeight] = useState(undefined);
  const intl = useIntl();
  if (updates.length < 1) {
    return <></>;
  }

  const firstPost = updates[0].node;

  const otherPosts = updates.length > 1 ? updates.slice(1, updates.length) : [];

  return (
    <>
      <Row xs="1" lg="2" className="my-5">
        <div
          className={`highlighted-article-picture px-3 ${
            isSmallScreen ? 'small-screen' : ''
          }`}
        >
          <Image
            src={
              firstPost.featuredImage.node?.localFile?.publicURL ||
              firstPost.featuredImage.node?.uri
            }
            width="100%"
            height={height || 550} // || 500 is for highlighted article with no featured image
            onLoad={imgRef => setHeight(imgRef.target.offsetWidth)}
            borderRadius={5}
          />
        </div>
        <div
          className={`d-flex align-items-center ${
            isExtraSmallScreen ? 'px-3 py-5' : 'p-5'
          }`}
        >
          <div>
            <div className="d-flex align-items-center mb-3">
              {firstPost.categories && firstPost.categories.length > 0 && (
                <Button className="highlighted-post-category px-4 py-2 mr-5">
                  {firstPost.categories.node[0].name}
                </Button>
              )}
              <IntlContextConsumer>
                {({ language: currentLocale }) => (
                  <>
                    <div className="highlighted-post-date">
                      {currentLocale === 'fr'
                        ? firstPost.dateFr
                        : firstPost.dateEn}
                    </div>
                  </>
                )}
              </IntlContextConsumer>
            </div>
            <div
              className="highlighted-post-title mb-3"
              dangerouslySetInnerHTML={{ __html: firstPost.title }}
            />
            <div
              className="highlighted-post-excerpt mb-3"
              dangerouslySetInnerHTML={{ __html: firstPost.excerpt }}
            />
            <Link to={`/update/${firstPost.slug}`}>
              <Button
                color="primary"
                className="px-4 py-2 highlighted-post-link"
                size="lg"
              >
                {intl.formatMessage(messages.readMore)}
              </Button>
            </Link>
          </div>
        </div>
      </Row>
      <Row lg="3" md="2" xs="1">
        {otherPosts.map(({ node: update }) => (
          <UpdateCard post={update} key={update.id} />
        ))}
      </Row>
    </>
  );
};
export default UpdatesList;
UpdatesList.propTypes = {
  updates: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        dateAgoFr: PropTypes.string,
        dateAgoEn: PropTypes.string,
        date: PropTypes.string,
        excerpt: PropTypes.string,
        type: PropTypes.string,
        tags: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string
            })
          )
        }),
        categories: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string
            })
          )
        }),
        author: PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            name: PropTypes.string
          })
        }),
        featuredImage: PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            publicURL: PropTypes.string,
            uri: PropTypes.string
          })
        })
      })
    })
  ).isRequired
};
