import { defineMessages } from 'react-intl';

export const scope = 'activities';

export default defineMessages({
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: 'Read More'
  },
  loadMore: {
    id: `${scope}.loadMore`,
    defaultMessage: 'Load More'
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View'
  },
  updates: {
    id: `${scope}.updates`,
    defaultMessage: 'Updates'
  },
  events: {
    id: `${scope}.events`,
    defaultMessage: 'Events'
  },
  topArticles: {
    id: `${scope}.topArticles`,
    defaultMessage: 'Top Articles'
  },
  activities: {
    id: `${scope}.activities`,
    defaultMessage: 'Activities'
  }
});
