import React, { useState, useRef } from 'react';
import LazyLoad from 'react-lazyload';

import imageError from '../assets/images/null.png';

const LazyImage = ({
  src,
  alt,
  width,
  height,
  className,
  borderRadius,
  onLoad,
  disableLoadAnimation
}) => {
  const refPlaceholder = useRef();
  const [showErr, setShowErr] = useState(false);

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };

  const displayErro = () => {
    refPlaceholder.current.remove();
    setShowErr(true);
  };

  return (
    <div
      className={`image-wrapper ${className || ''}`}
      style={{ width, height }}
    >
      {(src !== undefined && src != null && !showErr && (
        <>
          <div
            className={`image-preloader${
              disableLoadAnimation ? ' image-disable-load-animation' : ''
            }`}
            ref={refPlaceholder}
            style={{ borderRadius: borderRadius || 0 }}
          />
          <div
            className={`image-error-${showErr ? 'show' : 'hide'}`}
            style={{ borderRadius: borderRadius || 0 }}
          />
          <LazyLoad>
            <img
              onLoad={imgRef => {
                removePlaceholder();
                if (
                  onLoad &&
                  {}.toString.call(onLoad) === '[object Function]'
                  // avoid stupid mistakes
                ) {
                  onLoad(imgRef);
                }
              }}
              onError={displayErro}
              src={src}
              alt={alt}
              className="image-dispaly-fit"
              width={width}
              height={height}
              style={{ borderRadius: borderRadius || 0 }}
            />
          </LazyLoad>
        </>
      )) || (
        <div
          className="image-error-show d-flex justify-content-center align-items-center"
          style={{ borderRadius: borderRadius || 0 }}
        >
          <img src={imageError} alt="Error" width={32} height={32} />
        </div>
      )}
    </div>
  );
};

export default LazyImage;
