import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { Link, IntlContextConsumer } from 'gatsby-plugin-intl';
import randomColor from 'randomcolor';

import Image from './Image';

const UpdateCard = ({ post, categoryFilter, tagFiler, type = 'update' }) => {
  return (
    <>
      <div className="w-100 d-flex justify-content-center mb-5 px-3">
        <div className="article-card-body">
          <Link to={`/${type}/${post.slug}`}>
            <div className="article-card-image">
              <Image
                src={
                  post.featuredImage?.node.localFile?.publicURL ||
                  post.featuredImage?.node.sourceUrl
                }
                alt={post.title}
                height={250}
                width="100%"
                borderRadius={5}
              />
            </div>
          </Link>
          <div className="article-content pt-3">
            <div className="d-flex article-cat-and-date">
              {post.categories && post.categories.nodes.length > 0 && (
                <>
                  <Button
                    color="link"
                    className="article-cat-and-date-btn p-0"
                    onClick={() => {
                      if (
                        categoryFilter &&
                        {}.toString.call(categoryFilter) === '[object Function]'
                        // avoid stupid mistakes
                      ) {
                        categoryFilter(post.categories[0].name);
                      }
                    }}
                  >
                    {post.categories.nodes[0].name}
                  </Button>
                  <div className="px-2">-</div>
                </>
              )}
              <IntlContextConsumer>
                {({ language: currentLocale }) => (
                  <div className="post-card-date">
                    {currentLocale === 'fr' ? post.dateAgoFr : post.dateAgoEn}
                  </div>
                )}
              </IntlContextConsumer>
            </div>
            <Link className="article-title mt-3" to={`/${type}/${post.slug}`}>
              <div dangerouslySetInnerHTML={{ __html: post.title }} />
            </Link>
            {type !== 'update' && (
              <Link
                to={`/member/${post.author.node.slug}`}
                className="article-author"
              >
                <div className="d-flex align-items-center mt-3">
                  <Image
                    scr={
                      post.author.node?.avatar?.url ||
                      post.author.node?.avatar?.url
                    }
                    width={54}
                    height={54}
                    borderRadius={27}
                    className="mr-3"
                  />
                  <div className="article-author-name">
                    {post.author.node?.name}
                  </div>
                </div>
              </Link>
            )}
            <div className="mt-3">
              {post.tags &&
                post.tags.nodes.map(tag => (
                  <Button
                    key={tag.id}
                    className="mr-2 mb-2 article-card-tag"
                    style={{
                      backgroundColor: randomColor({
                        luminosity: 'light',
                        hue: 'blue',
                        seed: `${tag.name} ABC`
                      })
                    }}
                    onClick={() => {
                      if (
                        tagFiler &&
                        {}.toString.call(tagFiler) === '[object Function]'
                        // avoid stupid mistakes
                      ) {
                        tagFiler(tag.name);
                      }
                    }}
                  >{`#${tag.name} `}</Button>
                ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

UpdateCard.propTypes = {
  post: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    title: PropTypes.string,
    content: PropTypes.string,
    date: PropTypes.string,
    dateAgoFr: PropTypes.string,
    dateAgoEn: PropTypes.string,
    excerpt: PropTypes.string,
    type: PropTypes.string,
    tags: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          name: PropTypes.string
        })
      )
    }),
    categories: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          slug: PropTypes.string,
          name: PropTypes.string
        })
      )
    }),
    author: PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        name: PropTypes.string
      })
    }),
    featuredImage: PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        publicURL: PropTypes.string,
        uri: PropTypes.string
      })
    })
  }).isRequired
};
export default UpdateCard;
