import React from 'react';
import { Row } from 'reactstrap';
import PropTypes from 'prop-types';

import TopArticleCard from './PostCard';

const TopArticlesList = ({ articles }) => {
  return (
    <Row lg="3" md="2" xs="1">
      {articles.map(({ node: article }) => (
        <TopArticleCard post={article} type="article" key={article.id} />
      ))}
    </Row>
  );
};
TopArticlesList.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        date: PropTypes.string,
        dateAgoFr: PropTypes.string,
        dateAgoEn: PropTypes.string,
        excerpt: PropTypes.string,
        type: PropTypes.string,
        tags: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string
            })
          )
        }),
        categories: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string
            })
          )
        }),
        author: PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            name: PropTypes.string
          })
        }),
        featuredImage: PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            publicUrl: PropTypes.string,
            uri: PropTypes.string
          })
        })
      })
    })
  ).isRequired
};
export default TopArticlesList;
