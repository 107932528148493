import { defineMessages } from 'react-intl';

export const scope = 'footer';

export default defineMessages({
  copyrightLineOne: {
    id: `${scope}.copyrightLineOne`,
    defaultMessage: 'Copyright © AfricanCrossroad 2021'
  },
  copyrightLineTwo: {
    id: `${scope}.copyrightLineTwo`,
    defaultMessage: 'All rights reserved'
  },
  latestPosts: {
    id: `${scope}.latestPosts`,
    defaultMessage: 'Latest Posts'
  }
});
