import React, { useState } from 'react';
import {
  Link,
  useIntl,
  IntlContextConsumer,
  changeLocale
} from 'gatsby-plugin-intl';
import { Button } from 'reactstrap';
import { nanoid } from 'nanoid';
import UseAnimations from 'react-useanimations';
import menu from 'react-useanimations/lib/menu2';

import logo from '../assets/images/logo.png';
import hivosLogo from '../assets/images/hivos-logo.png';
import ig from '../assets/images/ig-header.png';
import fb from '../assets/images/fb-header.png';
import tw from '../assets/images/tw-header.png';
import messages from '../messages/header';

import Image from './Image';

const Header = ({ isSmallScreen }) => {
  const intl = useIntl();

  const [expanded, setExpanded] = useState(false);
  const [activeLabel, setActiveLabel] = useState('default');

  const url = typeof window !== 'undefined' ? window.location.href : '';
  if (
    activeLabel !== 'default' &&
    (url.endsWith('/fr/') || url.endsWith('/en/')) &&
    url.indexOf('/about') === -1 &&
    url.indexOf('/activities') === -1 &&
    url.indexOf('/community') === -1 &&
    url.indexOf('/contact') === -1
  ) {
    setActiveLabel('default');
  } else if (activeLabel !== 'about' && url.indexOf('/about') !== -1) {
    setActiveLabel('about');
  } else if (
    activeLabel !== 'activities' &&
    url.indexOf('/activities') !== -1
  ) {
    setActiveLabel('activities');
  } else if (activeLabel !== 'community' && url.indexOf('/community') !== -1) {
    setActiveLabel('community');
  } else if (activeLabel !== 'contact' && url.indexOf('/contact') !== -1) {
    setActiveLabel('contact');
  }

  const languageName = {
    en: 'EN',
    fr: 'FR'
  };

  const LanguageSwitcher = () => (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) =>
        languages.map((language, index) => (
          <div className="d-flex" key={nanoid()}>
            <Button
              color="link"
              className={` p-0 language-switch ${
                language === currentLocale
                  ? 'active-lang-header'
                  : 'inactive-lang-header'
              }`}
              onClick={() => {
                changeLocale(language);
              }}
            >
              {languageName[language]}
            </Button>
            {index < languages.length - 1 && <div className="mx-2">/</div>}
          </div>
        ))
      }
    </IntlContextConsumer>
  );

  return (
    <>
      <div
        className={`main-header ${
          isSmallScreen && expanded ? 'header-expanded' : ''
        }`}
      >
        {(isSmallScreen && (
          <div className="d-flex justify-content-between align-items-center flex-wrap px-5 h-100 fade-in-4">
            <UseAnimations
              reverse={expanded}
              animation={menu}
              size={40}
              className="hamburger-menu"
              strokeColor="#1b1464"
              onClick={() => setExpanded(!expanded)}
            />
            <div className="header-logo">
              <Link to="/">
                <Image src={logo} alt="African Crossroads" height="80px" />
              </Link>
            </div>
            <div
              className={`w-100 ${
                expanded ? 'fade-in show' : 'fade-out hide'
              }-header-content d-flex justify-content-center align-items-center`}
            >
              <div className="text-center header-links">
                <div>
                  <Link
                    to="/about"
                    className={`header-link my-3${
                      activeLabel === 'about' ? ' active' : ''
                    }`}
                  >
                    {intl.formatMessage(messages.about)}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/activities"
                    className={`header-link my-3${
                      activeLabel === 'activities' ? ' active' : ''
                    }`}
                  >
                    {intl.formatMessage(messages.activities)}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/community"
                    className={`header-link my-3${
                      activeLabel === 'community' ? ' active' : ''
                    }`}
                  >
                    {intl.formatMessage(messages.community)}
                  </Link>
                </div>
                <div>
                  <Link to="/resources" className="header-link my-3">
                    {intl.formatMessage(messages.resources)}
                  </Link>
                </div>
                <div>
                  <Link
                    to="/contact"
                    className={`header-link my-3${
                      activeLabel === 'contact' ? ' active' : ''
                    }`}
                  >
                    {intl.formatMessage(messages.contact)}
                  </Link>
                </div>
                <div>-</div>
                <div className="d-flex justify-content-center">
                  <LanguageSwitcher />
                </div>
                <div>-</div>
                <div className="d-flex justify-content-center mt-2">
                  <a
                    href="https://www.instagram.com/africancrossroads/"
                    target="blank"
                    className="mr-3"
                  >
                    <Image src={ig} alt="instagram link" />
                  </a>
                  <a
                    href="https://twitter.com/AfricaCrossroad"
                    target="blank"
                    className="mr-3"
                  >
                    <Image src={tw} alt="twitter link" />
                  </a>
                  <a
                    href="https://www.facebook.com/africancrossroads/"
                    target="blank"
                  >
                    <Image src={fb} alt="facebook link" />
                  </a>
                </div>
                <div className="d-flex justify-content-center mt-5">
                  <Image src={hivosLogo} alt="Hivos logo" width={100} />
                </div>
              </div>
            </div>
          </div>
        )) || (
          <div className="d-flex justify-content-between px-5 align-items-center h-100 fade-in-4">
            <div className="header-logo">
              <Link to="/">
                <Image src={logo} alt="African Crossroads" height="80px" />
              </Link>
            </div>
            <div className="d-flex header-links">
              <Link
                to="/about"
                className={`header-link mx-2${
                  activeLabel === 'about' ? ' active' : ''
                }`}
              >
                {intl.formatMessage(messages.about)}
              </Link>
              |
              <Link
                to="/activities"
                className={`header-link mx-2${
                  activeLabel === 'activities' ? ' active' : ''
                }`}
              >
                {intl.formatMessage(messages.activities)}
              </Link>
              |
              <Link
                to="/community"
                className={`header-link mx-2${
                  activeLabel === 'community' ? ' active' : ''
                }`}
              >
                {intl.formatMessage(messages.community)}
              </Link>
              |
              <Link to="/resources" className="header-link mx-3">
                {intl.formatMessage(messages.resources)}
              </Link>
              |
              <Link
                to="/contact"
                className={`header-link mx-2${
                  activeLabel === 'contact' ? ' active' : ''
                }`}
              >
                {intl.formatMessage(messages.contact)}
              </Link>
            </div>
            <div className="d-flex justify-content-end header-right">
              <div className="mr-3">|</div>
              <LanguageSwitcher />
              <Image
                src={hivosLogo}
                alt="Hivos logo"
                width={70}
                className="mx-4"
              />
              <a
                href="https://www.instagram.com/africancrossroads/"
                target="blank"
                className="mr-3"
              >
                <Image src={ig} alt="instagram link" />
              </a>
              <a
                href="https://twitter.com/AfricaCrossroad"
                target="blank"
                className="mr-3"
              >
                <Image src={tw} alt="twitter link" />
              </a>
              <a
                href="https://www.facebook.com/africancrossroads/"
                target="blank"
              >
                <Image src={fb} alt="facebook link" />
              </a>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Header;
