import React from 'react';
import { Col } from 'reactstrap';
import { Link, useIntl } from 'gatsby-plugin-intl';
import { useStaticQuery, graphql } from 'gatsby';

import filterByLangage from '../utils/filterByLangage';
import logo from '../assets/images/logo.png';
import hivosLogo from '../assets/images/hivos-logo.png';
import thumbnail from '../assets/images/footer-post-thumb-placeholder.png';
import footerMessages from '../messages/footer';

import Image from './Image';

const Footer = ({ isSmallScreen = false }) => {
  const intl = useIntl();

  const data = useStaticQuery(graphql`
    {
      allUpdates: allWpPost(
        filter: { author: { node: { slug: { eq: "admin" } } } }
        sort: { fields: [date], order: DESC }
        limit: 6
      ) {
        group(field: language___slug) {
          edges {
            node {
              id
              slug
              title
              content
              dateAgo: date(fromNow: true)
              date(formatString: "D/MM/YYYY")
              excerpt
              tags {
                nodes {
                  id
                  slug
                  name
                }
              }
              categories {
                nodes {
                  id
                  slug
                  name
                }
              }
              author {
                node {
                  id
                  slug
                  name
                  avatar {
                    url
                  }
                }
              }
              featuredImage {
                node {
                  id
                  sourceUrl
                  localFile {
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const prePosts = filterByLangage(data.allUpdates.group, intl.locale).slice(
    0,
    2
  );

  const posts = prePosts.map(el => ({
    id: el.node.id,
    title: el.node.title,
    thumbnail:
      el.node.featuredImage?.node.localFile?.publicUrl ||
      el.node.featuredImage?.node.sourceUrl,
    url: `/${el.node.slug}`
  }));

  return (
    <div className="footer container py-5">
      {(isSmallScreen && (
        <>
          <div>
            <div className="d-flex mb-5 align-items-center justify-content-between">
              <Image src={logo} alt="African Crossroads logo" width={204} />
              <Image src={hivosLogo} alt="Hivos logo" width={99} />
            </div>
            <div className="copyright text-center mt-4">
              {intl.formatMessage(footerMessages.copyrightLineOne, {
                year: new Date().getFullYear()
              })}
            </div>
            <div className="copyright text-center">
              {intl.formatMessage(footerMessages.copyrightLineTwo)}
            </div>
          </div>
        </>
      )) || (
        <div className="d-flex">
          <Col lg="4">
            <div className="d-flex align-items-end">
              <Image
                src={logo}
                alt="African Crossroads logo"
                width={204}
                className="mr-3"
              />
              <Image src={hivosLogo} alt="Hivos logo" width={99} />
            </div>
            <div className="copyright mt-4">
              {intl.formatMessage(footerMessages.copyrightLineOne, {
                year: new Date().getFullYear()
              })}
            </div>
            <div className="copyright">
              {intl.formatMessage(footerMessages.copyrightLineTwo)}
            </div>
          </Col>
          <Col lg="8" className="pt-4">
            <div className="footer-latest-posts mt-1">
              {intl.formatMessage(footerMessages.latestPosts)}
            </div>
            <div className="d-flex justify-content-between mt-2">
              {posts.map(el => (
                <Link
                  to={`/update${el.url}`}
                  key={el.id}
                  className="footer-post-link mr-4"
                >
                  <div className="d-flex align-items-center footer-post">
                    <Image
                      src={
                        el.thumbnail && el.thumbnail.length !== 0
                          ? el.thumbnail
                          : thumbnail
                      }
                      width={68}
                      height={68}
                      className="mr-4"
                      alt={el.title}
                    />
                    <div
                      className="footer-post-title"
                      dangerouslySetInnerHTML={{ __html: el.title }}
                    />
                  </div>
                </Link>
              ))}
            </div>
          </Col>
        </div>
      )}
    </div>
  );
};

export default Footer;
