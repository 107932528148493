import React from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

import EventCard from './EventCard';

const EventsList = ({ events }) => {
  const sizes = ['8', '4', '4', '8'];
  if (events.length < 1) {
    return <></>;
  }

  return (
    <Row>
      {events.map(({ node: event }, index) => (
        <EventCard event={event} key={event.id} size={sizes[index % 4]} />
      ))}
    </Row>
  );
};
export default EventsList;
EventsList.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        id: PropTypes.string,
        slug: PropTypes.string,
        title: PropTypes.string,
        content: PropTypes.string,
        dateAgoFr: PropTypes.string,
        dateAgoEn: PropTypes.string,
        date: PropTypes.string,
        excerpt: PropTypes.string,
        type: PropTypes.string,
        tags: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string
            })
          )
        }),
        categories: PropTypes.shape({
          nodes: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string,
              slug: PropTypes.string,
              name: PropTypes.string
            })
          )
        }),
        author: PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            slug: PropTypes.string,
            name: PropTypes.string
          })
        }),
        featuredImage: PropTypes.shape({
          node: PropTypes.shape({
            id: PropTypes.string,
            publicUrl: PropTypes.string,
            uri: PropTypes.string
          })
        })
      })
    })
  ).isRequired
};
